<template>
  <div>
    <data-setting></data-setting>
  </div>
</template>

<script>
import DataSetting from '../../common-config/data-setting';
export default {
  components: {
    DataSetting
  }
};
</script>
