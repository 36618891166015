<template>
  <div class="data-setting">
    <!-- 已选择机构 -->
    <!-- header -->
    <div>
      <div class="path-header">
        <p class="path">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item v-for="item in pathList" :key="item.id" @click.native="handleClickPath(item)">
              <el-tooltip effect="dark" :enterable="false" :content="item.label" placement="top">
                <span class="path-item">{{ item.label }}</span>
              </el-tooltip>
            </el-breadcrumb-item>
          </el-breadcrumb>
          <el-button v-if="pathList.length !== 1" size="mini" style="margin-left: 24px" @click="handleBack"
            >返回上一级</el-button
          >
        </p>
        <div class="right">
          <div class="action">
            <el-button type="primary" @click="handleAddNewFolder">新建文件夹</el-button>
            <el-button :disabled="!currentFolderId" @click="handleEditFolder">修改文件夹</el-button>
            <el-button type="danger" :disabled="!currentFolderId" @click="handelDelete">删除文件夹</el-button>
          </div>
        </div>
      </div>
      <FileContentList
        :loading="loading"
        :file-list="folderList"
        @on-click="onClickRow"
        @on-dblclick="handleDblclick"
      />
    </div>
    <!-- 新增/编辑文件夹 对话框 -->
    <FolderDialog ref="folderDialogRef" @on-success="getFolderListData(pathList[pathList.length - 1].id)" />
  </div>
</template>

<script>
import { getItem } from '@/utils/storage.js';
import FileContentList from '@/components/FileContentList/index.vue';
import FolderDialog from './folderDialog.vue';
import { getFolderList, deleteFolder } from '@/api/super-admin/sys-setting/data-setting.js';

export default {
  name: 'DataSetting',
  components: { FileContentList, FolderDialog },
  data() {
    return {
      orgId: '',
      orgList: [],
      pathList: [{ id: '0', label: '全部文件' }],
      loading: false,
      folderList: [],
      currentFolderId: '',
      currentFolderName: '',
      module: '', //1安全，2环保，3职业健康
      visible: false
    };
  },
  created() {
    this.orgId = getItem('orgId');
    this.module = this.$route.name === 'DataSetting' ? 1 : this.$route.name === 'envOccupationaSetting' ? 2 : 3; //后续可能还有环保
    this.getFolderListData();
  },
  methods: {
    getFolderListData(pid) {
      this.loading = true;
      getFolderList({ tenantId: this.orgId, pid, module: this.module })
        .then(res => {
          this.folderList = res.data;
          this.folderList.forEach(item => {
            item.fileType = 1; // 文件夹
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 选择 机构
    handleChange(orgId) {
      if (orgId) {
        this.getFolderListData();
        this.pathList = [{ id: '0', label: '全部文件' }];
        this.currentFolderId = '';
      } else {
        /* 清空机构 */
        this.folderList = [];
        this.pathList = [{ id: '0', label: '全部文件' }];
        this.currentFolderId = '';
        this.currentFolderName = '';
      }
    },
    // 点击面包屑
    handleClickPath(item) {
      const { pathList } = this;
      const index = pathList.findIndex(ele => ele.id === item.id);
      if (index !== pathList.length - 1) {
        this.pathList = pathList.slice(0, index + 1);
        this.getFolderListData(item.id);
      }
    },
    // 返回上一级
    handleBack() {
      const { pathList } = this;
      this.pathList = pathList.slice(0, pathList.length - 1);
      this.getFolderListData(this.pathList[this.pathList.length - 1].id);
    },
    // 点击行
    onClickRow(row) {
      if (row.fileType === 1) {
        this.currentFolderId = row.id;
        this.currentFolderName = row.folderName;
      }
    },
    // 双击行进入下一级
    handleDblclick(row) {
      this.currentFolderId = '';
      this.currentFolderName = '';
      this.pathList.push({ id: row.id, label: row.folderName });
      this.getFolderListData(row.id);
    },
    // 新建文件夹
    handleAddNewFolder() {
      const { pathList, orgId } = this;
      const pid = pathList[pathList.length - 1].id;
      this.$refs.folderDialogRef.open({ pid, tenantId: orgId, module: this.module });
    },
    // 删除文件夹
    handelDelete() {
      this.$confirm('删除该文件夹后将删除该文件夹及所有下属文件，请确认是否删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          return deleteFolder({ id: this.currentFolderId });
        })
        .then(res => {
          this.$message.success('删除文件夹成功！');
          this.getFolderListData(this.pathList[this.pathList.length - 1].id);
        });
    },
    // 修改文件夹
    handleEditFolder() {
      const { pathList, orgId, currentFolderId, currentFolderName } = this;
      const pid = pathList[pathList.length - 1].id;
      const params = {
        id: currentFolderId,
        pid,
        tenantId: orgId,
        folderName: currentFolderName,
        module: this.module
      };
      this.$refs.folderDialogRef.open(params);
    }
  }
};
</script>

<style lang="less" scoped>
.data-setting {
  height: 100%;

  .target-org {
    height: 70px;
  }

  .no-data {
    height: calc(100% - 230px);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #979fa8;
  }
  .path-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    height: 56px;
    background: #f3f6fe;
    font-size: 14px;
    color: #979fa8;

    .path {
      display: flex;
      align-items: center;

      .path-item {
        display: inline-block;
        max-width: 200px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        cursor: pointer;
        transition: all 0.25s ease-in-out;

        &:hover {
          color: #005aab;
          font-weight: 500;
        }
      }
    }

    .right {
      display: flex;
      align-items: center;

      .action {
        margin-right: 16px;
      }
    }
  }
}
</style>
