<template>
  <el-table
    v-loading="loading"
    :data="fileList"
    @row-click="onRowClick"
    @row-dblclick="onDblClickRow"
    highlight-current-row
  >
    <el-table-column label="文件夹/文件名" show-overflow-tooltip fixed>
      <template slot-scope="{ row }">
        <div style="display:flex;align-items: center;">
          <span v-if="row.fileType === 1" class="file-name">
            <i class="icon-image"></i>
            <span style="margin-left: 16px">{{row.folderName}}</span>
          </span>
          <span v-else-if="row.fileType === 2" class="file-name">
            <i class="icon-image file-icon"></i>
            <span style="margin-left: 16px">{{row.fileName}}</span>
          </span>
        </div>
      </template>
    </el-table-column>
    <el-table-column align="center" width="100" label="上传人">
      <template slot-scope="{ row }">
        <span v-if="row.fileType === 1">--</span>
        <span v-else-if="row.fileType === 2">
          <span style="margin-left: 16px">{{row.createUserName}}</span>
        </span>
      </template>
    </el-table-column>
    <el-table-column align="center" width="200" label="所属机构" show-overflow-tooltip>
      <template slot-scope="{ row }">
        <span v-if="row.fileType === 1">--</span>
        <span v-else-if="row.fileType === 2">
          <span style="margin-left: 16px">{{row.orgName}}</span>
        </span>
      </template>
    </el-table-column>
    <el-table-column align="center" width="100" label="文件大小">
      <template slot-scope="{ row }">
        <span v-if="row.fileType === 1">--</span>
        <span v-else-if="row.fileType === 2">
          <span style="margin-left: 16px">{{row.fileSizeMb}}</span>
        </span>
      </template>
    </el-table-column>
    <el-table-column align="center" width="200" prop="createTime" label="上传时间" fixed="right"></el-table-column>
  </el-table>
</template>

<script>
export default {
  name: 'FileContentList',
  props: {
    fileList: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    // 单机 行
    onRowClick(row, column, event) {
      // if (row.fileType === 2) {
      this.$emit('on-click', row);
      // }
    },
    // 双击 行
    onDblClickRow(row, column, event) {
      if (row.fileType === 1) {
        this.$emit('on-dblclick', row);
      }
    }
  }
};
</script>

<style lang="less" scoped>
/deep/ .el-table__row {
  cursor: pointer;
  &:hover > td {
    background-color: #ddedff !important;
  }
}
.file-name {
  display: flex;
  align-items: center;

  .icon-image {
    display: inline-block;
    width: 32px;
    height: 32px;
    // background-color: pink;
    background: url('~@/assets/images/folder.png') no-repeat;
    background-size: cover;
  }

  .file-icon {
    background: url('~@/assets/images/file.png') no-repeat;
    background-size: cover;
  }
}
</style>
