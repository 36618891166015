<template>
  <el-dialog
    width="530px"
    :title="title"
    :visible.sync="visible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close="handleColse"
  >
    <el-form ref="form" inline label-width="100px" :model="paramsForm" :rules="rules">
      <el-form-item label="文件夹名称" prop="folderName">
        <el-input v-model="paramsForm.folderName" placeholder="请输入文件夹名称" style="width: 380px"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button @click="handleColse">取 消</el-button>
      <el-button type="primary" :loading="confirmLoading" @click="handleConfirm">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { postFolder } from '@/api/super-admin/sys-setting/data-setting.js';

export default {
  data() {
    return {
      visible: false,
      title: '新增',
      confirmLoading: false,
      paramsForm: {
        id: '', // 编辑用
        pid: '',
        folderName: '',
        tenantId: ''
      },
      rules: {
        folderName: [{ required: true, message: '请输入文件夹名称', trigger: 'change' }]
      }
    };
  },
  methods: {
    open({ id = '', pid, tenantId, folderName = '', module }) {
      this.visible = true;
      this.title = id ? '编辑' : '新增';
      this.$nextTick(() => {
        this.paramsForm.id = id;
        this.paramsForm.pid = pid;
        this.paramsForm.tenantId = tenantId;
        this.paramsForm.folderName = folderName;
        this.paramsForm.module = module;
      });
    },
    handleColse() {
      this.visible = false;
      this.$refs.form.resetFields();
      this.paramsForm.id = '';
    },
    handleConfirm() {
      this.$refs.form.validate(valid => {
        if (!valid) return;
        this.confirmLoading = true;

        postFolder(this.paramsForm)
          .then(res => {
            const { id } = this.paramsForm;
            this.$message.success(`${id ? '修改' : '新建'}文件夹成功`);
            this.$emit('on-success');
            this.handleColse();
          })
          .finally(() => {
            this.confirmLoading = false;
          });
      });
    }
  }
};
</script>
