var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"data":_vm.fileList,"highlight-current-row":""},on:{"row-click":_vm.onRowClick,"row-dblclick":_vm.onDblClickRow}},[_c('el-table-column',{attrs:{"label":"文件夹/文件名","show-overflow-tooltip":"","fixed":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[(row.fileType === 1)?_c('span',{staticClass:"file-name"},[_c('i',{staticClass:"icon-image"}),_c('span',{staticStyle:{"margin-left":"16px"}},[_vm._v(_vm._s(row.folderName))])]):(row.fileType === 2)?_c('span',{staticClass:"file-name"},[_c('i',{staticClass:"icon-image file-icon"}),_c('span',{staticStyle:{"margin-left":"16px"}},[_vm._v(_vm._s(row.fileName))])]):_vm._e()])]}}])}),_c('el-table-column',{attrs:{"align":"center","width":"100","label":"上传人"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.fileType === 1)?_c('span',[_vm._v("--")]):(row.fileType === 2)?_c('span',[_c('span',{staticStyle:{"margin-left":"16px"}},[_vm._v(_vm._s(row.createUserName))])]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"align":"center","width":"200","label":"所属机构","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.fileType === 1)?_c('span',[_vm._v("--")]):(row.fileType === 2)?_c('span',[_c('span',{staticStyle:{"margin-left":"16px"}},[_vm._v(_vm._s(row.orgName))])]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"align":"center","width":"100","label":"文件大小"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.fileType === 1)?_c('span',[_vm._v("--")]):(row.fileType === 2)?_c('span',[_c('span',{staticStyle:{"margin-left":"16px"}},[_vm._v(_vm._s(row.fileSizeMb))])]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"align":"center","width":"200","prop":"createTime","label":"上传时间","fixed":"right"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }